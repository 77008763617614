<template>
  <div class="pb-5 mb-2">
    <div class="d-flex align-items-center mb-4">
      <div class="col-md-6">
        <a @click="$router.back()" class="text-dark d-flex align-items-center cursor-pointer">
          <span class="d-flex align-items-center">
            <vs-icon icon="chevron_left"></vs-icon>
          </span>
          <span class="text-underline">Back</span>
        </a>
      </div>
      <div class="col-md-6">
        <div class="text-right" v-if="!loading">
          <button @click="reasonModal = true" class="btn btn-primary btn-site-outline px-3">
            Decline
          </button>
          <a :href="releaseData.resource_url" target="_blank" class="btn btn-primary btn-site px-3">
            <span style="display: flex">
              <vs-icon icon="language" size="16px"></vs-icon>
              <span> View Release </span>
            </span>
          </a>

          <button class="btn btn-primary btn-site px-3" @click="updateReleaseID = true">
            <span v-if="releaseData.fuga_release_id"> Update FUGA ID </span>
            <span v-else>Complete Delivery</span>
          </button>
        </div>
      </div>
    </div>
    <SectionLoading v-if="loading" text="Release loading..." />
    <div v-else>
      <div class="row">
        <div class="col-md-3">
          <div class="download-art">
            <img class="img-fluid albumImg w-full" :src="releaseData.cover_image_path || ''" :alt="releaseData.title" />
            <a :download="releaseData.title" :href="releaseData.cover_image_path" :title="releaseData.title"
              target="_blank">
              <vs-icon icon="download"></vs-icon>
            </a>
          </div>
        </div>
        <div class="col-md-9">
          <div class="releaseData">
            <p class="type">{{ releaseData.type }}</p>
            <div>
              <h1 class="title mb-3 hover-copy d-inline-block" @click="copyItem(releaseData.title)">
                {{ releaseData.title }}
              </h1>
            </div>
            <p class="mb-1" v-if="releaseData.user">
              <router-link :to="`/artists/${releaseData.user.uuid}`">{{ releaseData.user.artist_name }}
              </router-link><span class="text-dark">● {{ releaseData.label }} </span>
            </p>
            <p class="releaseinf">
              <span class="titl">DATE ADDED: </span><span class="text-dark">{{
                moment
                  .utc(new Date(releaseData.created_at))
                  .format("DD/MM/YYYY")
              }}</span>
            </p>
            <p class="releaseinf">
              <span class="titl">EXPECTED RELEASE DATE: </span><span class="text-dark">
                {{  getReleaseDate(releaseData.release_date) }}
              </span>
            </p>
            <p class="releaseinf">
              <span class="titl">STATUS: </span><span class="text-dark">{{
                releaseData.status | capitalize
              }}</span>
            </p>
            <vs-tooltip class="d-inline-block" position="bottom" text="Copy Release ID">
              <p @click="copyReleaseID()" class="releaseinf idView cursor-pointer">
                <span class="titl">FUGA ID: </span><span class="text-dark">{{
                  releaseData.fuga_release_id || "Not yet updated"
                }}</span>
              </p>
            </vs-tooltip>
            <input style="opacity: 0; position: absolute; top: 0; left: 0" type="text"
              :value="releaseData.fuga_release_id || ''" id="copy-text" />
          </div>
        </div>
      </div>

      <div class="card siteCard mt-3">
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">Artist</th>
                <th scope="col">Writers</th>
                <th scope="col">Creators</th>
                <th scope="col">Recording Year</th>
                <th scope="col">Stores</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(release, index) in releaseData.tracks" :key="index">
                <td>
                  <p class="text-dark mb-0">{{ index + 1 }}</p>
                </td>

                <td>
                  <div class="playBtn" @click="downloadAudioFile(release.audio_path, release.id)">
                    <img v-if="$store.getters.audioPlay.status &&
                      $store.getters.audioPlay.id == release.id
                      " src="/images/play.svg" height="20" alt="" />
                    <vs-icon v-else icon="play_circle_filled "></vs-icon>
                    <div class="ml-2">
                      <span class="text-dark mb-0 title">{{
                        release.title
                      }}</span>
                      <div>
                        <span class="explicit" v-if="release.explicit_content == 'Yes'"
                          style="font-size: 12px">Explicit</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p v-if="release.isrc" class="isrc_code hover-copy" @click="copyItem(release.isrc)">
                      {{ release.isrc }}
                    </p>
                  </div>
                </td>
                <td>
                  <p class="creatorList hover-copy" @click="copyItem(release.artist_name)">
                    <span>
                      {{ release.artist_name }}
                    </span>
                  </p>
                </td>

                <td>
                  <p class="text-dark mb-0 creatorList">
                    <span v-for="(writer, index) in release.writers" :key="index">{{ writer.name }}
                      <span v-if="index + 1 < release.writers.length">, </span>
                    </span>
                  </p>
                </td>

                <td>
                  <p class="text-dark mb-0 creatorList">
                    <span v-for="(artist, index) in release.artists" :key="index">{{ artist.name }} <span>({{ artist.role
                    }})</span>
                      <span v-if="index + 1 < release.artists.length">, </span>
                    </span>
                  </p>
                </td>

                <td>
                  <p class="text-dark mb-0 hover-copy" @click="copyItem(release.year)">
                    {{ release.year }}
                  </p>
                </td>
                <td>
                  <p class="text-dark mb-0 creatorList">
                    <span v-for="(store, index) in release.stores" :key="index">{{ store.name }}
                      <span v-if="index + 1 < release.stores.length">, </span>
                    </span>
                  </p>
                </td>
                <td>
                  <vs-button @click="updateISRC(release)" color="primary" type="flat" class="mb-2 mr-2" icon="edit"
                    size="small">Update ISRC</vs-button>
                  <vs-button @click="downloadAudioFile(release.audio_path, release.id)" color="primary" type="flat"
                    icon="file_download" class="mb-2 mr-2" size="small">Download</vs-button>
                  <vs-button v-if="release.lyrics" @click="viewLyricsAction(release.lyrics)" color="primary" type="flat"
                    icon="lyrics" size="small">View Lyrics</vs-button>
                  <vs-button class="text-left" v-if="release.artists.length > 0" @click="viewSplitListing(release.artists)" color="primary"
                    type="flat" icon="percent" size="small">View Contributors/Split</vs-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card siteCard mt-3">
        <div class="p-3">
          <h5 class="text-dark mb-4">Release Data</h5>
          <div class="row">
            <div class="col-md-3">
              <div class="metaDisplay">
                <p class="headi">Title</p>
                <p class="data hover-copy" @click="copyItem(releaseData.title)">
                  {{ releaseData.title }}
                </p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="metaDisplay">
                <p class="headi">Label</p>
                <p class="data hover-copy" @click="copyItem(releaseData.label)">
                  {{ releaseData.label }}
                </p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="metaDisplay">
                <p class="headi">Genre</p>
                <p class="data hover-copy" @click="copyItem(releaseData.genre)">
                  {{ releaseData.genre }}
                </p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="metaDisplay">
                <p class="headi">Language</p>
                <p class="data hover-copy" @click="copyItem(releaseData.language)">
                  {{ releaseData.language }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card siteCard mt-3">
        <div class="p-3">
          <div class="row">
            <div class="col-md-4">
              <div>
                <p class="creatorTitle">Total Stream Count</p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                    releaseData.total_stream_count | number("0,0")
                  }}</span>
                </h3>
              </div>

              <div>
                <p class="creatorTitle">Total Downloads</p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                    releaseData.total_download_count | number("0,0")
                  }}</span>
                </h3>
              </div>

              <div>
                <p class="creatorTitle">Total Earnings</p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                    releaseData.earnings | currency("€", 2)
                  }}</span>
                </h3>
              </div>
            </div>
            <div class="col-md-8">
              <v-chart class="chart" :option="infoChart" />
            </div>
          </div>
        </div>
      </div>

      <div class="card siteCard mt-3">
        <div class="p-3">
          <v-chart class="trackChart" :option="trackChart" />
        </div>
      </div>

      <!-- <div class="card siteCard mt-3">
        <div class="p-3">
          <h1>PLatform Specific</h1>
          <v-chart class="trackChart" :option="trackChart" />
        </div>
      </div> -->
    </div>

    <vs-popup class="updateModal" title="Update Fuga ID" :active.sync="updateReleaseID">
      <form id="fuga-form" @submit.prevent="updateFudaID" aria-label="fuga" role="form">
        <div class="form-group">
          <label class="text-dark" to="id">Fuga ID</label>
          <input v-model="fuga_id" required tabindex="1" id="id" class="form-control" />
        </div>

        <div class="form-group text-center mt-5">
          <button :disabled="fugaLoading" type="submit" class="btn btn-primary btn-block btn-site px-5">
            Update ID
            <BtnLoading v-if="fugaLoading" class="d-inline-block" height="18" />
          </button>
        </div>
      </form>
    </vs-popup>

    <vs-popup class="updateModal" title="Update ISRC number" :active.sync="isrcView">
      <form id="isrc-form" @submit.prevent="updateISRCData" aria-label="isrc" role="form">
        <div class="form-group">
          <label class="text-dark" to="id">ISRC Number</label>
          <input v-model="isrc_number" required tabindex="1" id="id" class="form-control" />
        </div>

        <div class="form-group text-center mt-5">
          <button :disabled="isrcLoading" type="submit" class="btn btn-primary btn-block btn-site px-5">
            Update ISRC
            <BtnLoading v-if="isrcLoading" class="d-inline-block" height="18" />
          </button>
        </div>
      </form>
    </vs-popup>

    <vs-popup class="updateModal" title="Lyrics" :active.sync="lyricsView">
      <p class="lyricsText hover-copy" @click="copyItem(lyricsData)">
        {{ lyricsData }}
      </p>
    </vs-popup>

    <vs-popup class="rateModal" :title="`Add reason to decline`" :active.sync="reasonModal">
      <form class="p-4" @submit.prevent="updateRelease('rejected')" role="form">
        <vs-select style="width: 100%" label="Select reason" class="mb-3" v-model="reasonSelected">
          <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item, index) in optionsListing" />
        </vs-select>
        <vs-textarea v-if="reasonSelected == 'Others'" required label="Reason to decline" v-model="reason" />

        <div class="form-group text-center mt-5">
          <button type="submit" :disabled="btnLoading" class="btn btn-primary btn-block btn-site px-5">
            Send Reason
            <BtnLoading v-if="btnLoading" class="d-inline-block" height="18" />
          </button>
        </div>
      </form>
    </vs-popup>

    <vs-popup class="updateModal" title="Contributors / Split" :active.sync="splitView">
      <div>
        <div class="split-card" v-for="(split, index) in splitData" :key="index" v-if="split.split_percentage">
          <div class="split-item">
            <h2>{{ split.name }}</h2>
            <p>{{ split.email }}</p>
            <div>
              <span class="badge badge-primary text-white">
                {{ split.role }}
              </span>
            </div>
            <div v-if="split.split_percentage" class="mt-2">
              <span class="mb-1">Split Percentage: <span class="font-bold">
                  {{ split.split_percentage }}%
                </span>
              </span>

              <div class="split-timeline">
                <div class="base" :style="`width: ${split.split_percentage}%`">
                </div>
                <div class="base-back"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <input style="opacity: 0; position: absolute; top: 0; left: 0" type="text" v-model="itemCopy" id="item-copy" />
  </div>
</template>
<script>
import SectionLoading from "../../../components/SectionLoading.vue";
import BtnLoading from "@/components/BtnLoading.vue";
import moment from "moment";


export default {
  name: "Release",
  components: {
    SectionLoading,
    BtnLoading,
  },
  data() {
    return {
      loading: false,
      itemCopy: "",
      releaseData: {},
      updateReleaseID: false,
      fugaLoading: false,
      isrcLoading: false,
      lyricsView: false,
      reasonModal: false,
      reasonSelected: "",
      splitView: false,
      splitData: {},
      optionsListing: [
        {
          text: "We flagged your release because we noticed an error within your metadata. Please review and redeliver",
        },
        {
          text: "Your release was flagged because on copyright grounds. Please reach out to support@melior.africa for more information",
        },
        { text: "Others" },
      ],
      btnLoading: false,
      lyricsData: "",
      isrc_number: "",
      fuga_id: "",
      isrcView: false,
      activeTrack: {},
      trackChart: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            name: "Downloads",
            type: "bar",
            color: "#f88f80",
            data: [],
          },
          {
            name: "Streams",
            type: "bar",
            color: "#f8c9a0",
            data: [],
          },
          {
            name: "Earnings",
            type: "bar",
            color: "#f8f0a0",
            data: [],
          },
        ],
      },
      infoChart: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            color: ["#f88f80", "#f8c9a0", "#f8f0a0", "#a0f8a0", "#a0f8f0"],
            itemStyle: {
              borderRadius: 5,
            },
            data: [],

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0)",
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getReleaseByID();
  },
  methods: {
    getReleaseDate(date) {
      if ( date.length > 10) {
        return moment.utc(date).format("DD/MM/YYYY");
      }
      return date
    },
    updateRelease(status) {
      this.$vs.loading();
      this.btnLoading = true;
      const uuid = this.$route.params.id;
      let fetch = {
        path: `admin/incoming/${uuid}/update`,
        data: {
          status,
          ...(status == "rejected" && {
            reason:
              this.reasonSelected == "Others"
                ? this.reason
                : this.reasonSelected,
          }),
        },
      };
      this.$store
        .dispatch("putRequest", fetch)
        .then(() => {
          this.$toast.success(
            "Release status updated successfully",
            "Release status updated",
            this.$toastPosition
          );
          this.reasonModal = false;
          this.$vs.loading.close();
          this.btnLoading = false;
          this.reasonSelected = "";
          this.reason = "";
          setTimeout(() => {
            location.reload();
          }, 1500);
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.btnLoading = false;
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Release Status Update Failed",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Unable to update release status",
              "Release Status Update Failed",
              this.$toastPosition
            );
          }
        });
    },
    updateISRC(release) {
      this.isrcView = true;
      this.activeTrack = release;
    },
    copyReleaseID() {
      if (this.releaseData.fuga_release_id) {
        let copyText = document.getElementById("copy-text");
        copyText.select();
        document.execCommand("copy");

        this.$toast.info(
          "Release ID" +
          this.releaseData.fuga_release_id +
          " has been copied to clipboard",
          "Release ID",
          this.$toastPosition
        );
      } else {
        this.$toast.error(
          "Release ID",
          "Release ID not found",
          this.$toastPosition
        );
      }
    },
    updateISRCData() {
      this.isrcLoading = true;

      let fetch = {
        path: `admin/releases/update-ISRC/${this.activeTrack.uuid}?isrc=${this.isrc_number}`,
        data: {},
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then(() => {
          this.isrcView = false;
          this.isrc_number = "";
          this.$toast.success(
            "ISRC Number",
            "Successfully updated ISRC Number",
            this.$toastPosition
          );
          this.isrcLoading = false;
          this.getReleaseByID();
        })
        .catch((err) => {
          this.isrcLoading = false;
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "ISRC Number",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Unable to update ISRC Number",
              "ISRC Number",
              this.$toastPosition
            );
          }
        });
    },
    updateFudaID() {
      this.fugaLoading = true;

      let fetch = {
        path: `admin/releases/${this.$route.params.id}`,
        data: {
          fuga_release_id: this.fuga_id,
        },
      };
      this.$store
        .dispatch("putRequest", fetch)
        .then((resp) => {
          this.updateReleaseID = false;
          this.fuga_id = "";
          this.$toast.success(
            "Fuga Release ID",
            "Successfully updated Fuga Release ID",
            this.$toastPosition
          );
          this.fugaLoading = false;
          this.getReleaseByID();
        })
        .catch((err) => {
          this.fugaLoading = false;
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Fuga Release ID",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Unable to update Fuga Release ID",
              "Fuga Release ID",
              this.$toastPosition
            );
          }
        });
    },
    getReleaseByID() {
      this.loading = true;
      const uuid = this.$route.params.id;
      let fetch = {
        path: `/admin/releases/${uuid}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.releaseData = resp.data.data;
          this.infoChart.series[0].data = [
            { value: this.releaseData.stream_count, name: "Stream Count" },
            { value: this.releaseData.download_count, name: "Download Count" },
            { value: this.releaseData.earnings, name: "Earnings" },
          ];

          for (let i = 0; i < this.releaseData.tracks.length; i++) {
            this.trackChart.yAxis.data.push(this.releaseData.tracks[i].title);

            this.trackChart.series[0].data.push(
              this.releaseData.tracks[i].download_count
            );

            this.trackChart.series[1].data.push(
              this.releaseData.tracks[i].stream_count
            );

            this.trackChart.series[2].data.push(
              this.releaseData.tracks[i].earnings
            );
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Release Info",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Release Info",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    downloadAudioFile(source) {
      let url = source;
      window.open(url, "_blank");
    },
    playAudio(source, id) {
      this.$store.commit("audioPlay", {
        status: true,
        source,
        id,
      });
    },
    copyItem(item) {
      this.itemCopy = item;
      setTimeout(() => {
        let copyText = document.getElementById("item-copy");
        copyText.select();
        document.execCommand("copy");
      }, 1000);

      this.$toast.info("Item copied to clipboard", "", {
        position: "topCenter",
      });
    },
    viewLyricsAction(item) {
      this.lyricsView = true;
      this.lyricsData = item;
    },
    viewSplitListing(item) {
      this.splitView = true;
      this.splitData = item;
    },
  },
};
</script>
<style lang="scss">
.albumImg {
  border-radius: 10px;
}

.releaseData {
  .type {
    color: black;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }

  .title {
    font-size: 5em;
    line-height: 1;
    font-weight: bold;
    color: black;
  }
}

.metaDisplay {
  .headi {
    text-transform: uppercase;
    font-size: 12px;
    color: black;
    letter-spacing: 1px;
    border-radius: 26px;
    margin: 0;
  }

  .data {
    color: black;
  }
}

.updateModal {
  .vs-popup {
    width: 342px !important;
  }
}

.chart {
  width: 100%;
  height: 300px;
}

.trackChart {
  min-height: 300px;
}

.isrc_code {
  font-size: 11px;
  color: black;
  display: inline-block;
  background: #00000024;
  padding: 1px 7px;
  border-radius: 3px;
  margin-top: 7px;
}

.hover-copy {
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.3;
    text-decoration: underline;
  }
}

.download-art {
  position: relative;

  .vs-icon {
    cursor: pointer;
    background: #71b682;
    color: white;
    font-size: 17px;
    padding: 7px;
    border-radius: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    border: 3px solid white;
  }
}

.explicit {
  font-size: 11px;
  display: inline;
  text-transform: uppercase;
  color: #d21d1d;
  border: 2px solid #d21d1d;
  padding: 3px 5px;
  border-radius: 3px;
}

.lyricsText {
  color: black;
  font-size: 14px;
}
</style>
